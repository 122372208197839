<template>
  <template v-if="platformConfig.platform == 26">
    <ww-open-data :type="type" :openid="openid" />
  </template>
  <template v-else-if="platformConfig.platform == 31">
    <span v-if="type == 'userName' && openid.length < 6">{{ openid }}</span>
    <dt-open-data
      v-else-if="type == 'userName'"
      open-type="userName"
      :open-id="openid"
    />
    <template v-else>
      <dt-open-data
        open-type="deptName"
        :open-id="openid"
        v-if="Number(openid).toString() != 'NaN'"
      />
      <span v-else>{{ openid }}</span>
    </template>
  </template>
  <span v-else>{{ openid }}</span>
</template>

<script>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import { logout } from "@/api/user";
import { ToLogin } from "@/utils/request.js";
export default {
  name: "OpenData",
  props: ["type", "openid"],
  setup() {
    const store = useStore();
    const platformConfig = computed(() => store.getters.platformConfig);
    const companyInfo = computed(() => store.getters.companyInfo);

    onMounted(() => {
      if (platformConfig.value.platform == 26) {
        setTimeout(() => {
          if (window.WWOpenData) {
            if (WWOpenData.checkSession) {
              WWOpenData.checkSession({
                success() {
                  WWOpenData.bindAll(document.querySelectorAll("ww-open-data"));
                },
                fail() {
                  console.log("WWOpenData.checkSession fail");
                  // logout().then((res) => {
                  //   ToLogin();
                  // });
                },
              });
            }
          }
        }, 0);
      }
      if (platformConfig.value.platform == 31) {
        window.DTOpenData.update(document.querySelectorAll("dt-open-data"));
        setTimeout(() => {
          window.DTOpenData.update(document.querySelectorAll("dt-open-data"));
        }, 500);
        //   let href =
        //     "https://login.dingtalk.com/oauth2/auth?response_type=code&client_id=dingwa4tibze6jwz7mgv&scope=openid&state=dddd&redirect_uri=" +
        //     encodeURIComponent(
        //       "http://auth.dingtalk.com/login?redirectUri=" +
        //         encodeURIComponent(location.href)
        //     );
        //   window.location.href = href;
      }
    });

    return {
      platformConfig,
      companyInfo,
    };
  },
};
</script>
